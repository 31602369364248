/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { graphql } from "gatsby";
import SEO from "@components/seo";
import CollectionListingContainer from "@components/CollectionListingContainer";
const Impacts = (props) => {
  const { category } = props.data.craft;

  return (
    <React.Fragment>
      <SEO
        metadata={{
          metaTitle: category.title,
        }}
        location={props.location}
      />
      <CollectionListingContainer category={category} listing="socialImpacts" />
    </React.Fragment>
  );
};

export default Impacts;

export const query = graphql`
  query SocialImpactsQuery($id: Craft_QueryArgument) {
    craft {
      category(id: [$id]) {
        ... on Craft_socialImpacts_Category {
          title
        }
      }
    }
  }
`;
